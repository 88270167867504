<template>
  <div class="constrain textblock">
    <div class="row-12">
      <div class="md:offset-3 md:col-9">
        <div class="text pb-16" v-html="payload.text" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: Object,
  },
  mounted() {
    // console.log(this.payload);
  },
};
</script>

<style lang="scss" scoped>
.textblock:not(:last-child) {
  // margin-bottom: 6rem;
}
.textblock:first-child {
  margin-top: 6rem;
}

.text {
  &:not(:last-child) {
    border-bottom: 2px solid var(--color-light-blue);
  }
  &:deep(h3) {
    margin-bottom: 1rem;
  }
  &:deep(p) {
    color: var(--color-navy);
    line-height: 1.75;
  }
  &:deep(ul) {
    margin: 3rem 0 3rem px(26);
    color: var(--color-navy);
    padding: 0;
    li {
      margin-bottom: 1rem;
      line-height: 1.75;
    }
  }
}
</style>
